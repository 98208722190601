import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogContainer from '../components/BlogContainer';

const BlogPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulBlogPage {
          edges {
            node {
              featuredBlogArticle {
                slug
              }
            }
          }
        }
        allContentfulBlog {
          edges {
            node {
              heroImage {
                gatsbyImageData
              }
              slug
              title
              teaser {
                childMarkdownRemark {
                  html
                }
              }
              updatedAt
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='Blog'>
        <BlogContainer
          articles={data.allContentfulBlog.edges.map((entry) => entry.node)}
          featuredArticleSlug={data.allContentfulBlogPage.edges[0].node.featuredBlogArticle.slug}
        />
      </Layout>
    )}
  />
);

export default BlogPage;
