import React from 'react'
import { BlogArticle } from '../types/BlogArticle'
import BlogArticleCard from './BlogArticleCard'
import { Grid } from '@mui/material'

interface PropTypes {
  articles: BlogArticle[]
  featuredArticleSlug: string
}

function BlogContainer({ articles, featuredArticleSlug }: PropTypes) {
  const featuredArticle = articles.find(
    (article) => article.slug === featuredArticleSlug
  )

  function renderArticleCard(article) {
    if (article.slug === featuredArticleSlug) {
      return null
    }

    return (
      <Grid key={article.slug} item xs={12}>
        <BlogArticleCard {...article} />
      </Grid>
    )
  }

  return (
    <Grid container spacing={4}>
      {featuredArticle && (
        <Grid key={featuredArticleSlug} item xs={12}>
          <BlogArticleCard {...featuredArticle} featured />
        </Grid>
      )}
      {articles
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map((article) => renderArticleCard(article))}
    </Grid>
  )
}

export default BlogContainer
