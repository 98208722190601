import React from 'react';
import { Card } from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BlogArticle } from '../types/BlogArticle';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '../breakpoints';

const link = css`
  text-decoration: none;
`;

const H1 = styled.h1`
  font-family: 'Tangerine', cursive;
  margin-top: 0;
`;

interface Featured {
  featured: boolean;
}

const StyledBlogArticleCard = styled(Card)<Featured>`
  background: ${(props) => (props.featured ? props.theme.palette.primary.main : props.theme.palette.secondary.main)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => (props.featured ? props.theme.palette.common.white : props.theme.palette.primary.main)};
  letter-spacing: 1.1px;
  min-height: 288px;
  padding: 24px;
  position: relative;

  p {
    margin: 0;
    opacity: 0.8;
    text-align: left;
  }
`;

const img = css`
  border-radius: 12px;
  float: right;
  height: 120px;
  width: 120px;

  ${mq.sm(css`
    height: 200px;
    margin-left: 8px;
    width: 200px;
  `)}
`;

const ReadMore = styled.div`
  bottom: 16px;
  position: absolute;
  right: 32px;
`;

interface PropTypes extends BlogArticle {
  featured?: boolean;
}

function BlogArticleCard({ featured = false, heroImage, slug, teaser, title }) {
  return (
    <Link css={link} to={slug}>
      <StyledBlogArticleCard featured={featured}>
        {heroImage && <GatsbyImage css={img} alt={title} image={heroImage.gatsbyImageData} />}
        <H1>{title}</H1>
        {teaser && (
          <div
            dangerouslySetInnerHTML={{
              __html: teaser.childMarkdownRemark.html,
            }}
          />
        )}
        <ReadMore>Read More</ReadMore>
      </StyledBlogArticleCard>
    </Link>
  );
}

export default BlogArticleCard;
